import Vue from "vue";
import Vuex from "vuex";
import datos from "./info.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    obraSeleccionada: "casi-un-destino",
    info: []
  },
  mutations: {
    cambiarVistaObra(state, obra) {
      state.obraSeleccionada = obra;
    },
    getInfo(state, info) {
      state.info = info;
    }
  },
  actions: {
    getInfo: function({ commit }) {
      commit("getInfo", datos);
    }
  },
  modules: {}
});
