import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "home",
    component: loadView("Home")
  },
  {
    path: "/cv",
    name: "cv",
    component: loadView("Cv")
  },
  {
    path: "/produccion-artistica/:produccion",
    name: "producción artística",
    component: loadView("ProduccionArtistica")
  },
  {
    path: "/criticas",
    name: "criticas",
    component: loadView("Criticas")
  },
  {
    path: "/contacto",
    name: "contacto",
    component: loadView("Contacto")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
