<template>
  <nav class="main-nav navbar-expand-lg">
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div class="logo-contenedor">
        <router-link
          to="/"
          class="
            leading-relaxed
            inline-block
            mr-4
            py-2
            whitespace-no-wrap
            no-activo
          "
        >
          <h1 class="logo">
            beatriz rodríguez gauna
          </h1>
        </router-link>
        <button
          class="
            cursor-pointer
            text-xl
            leading-none
            px-3
            py-1
            border border-solid border-transparent
            rounded
            bg-transparent
            block
            lg:hidden
            outline-none
            focus:outline-none
            text-gray-600 text-2xl text-left
          "
          type="button"
          v-on:click="toggleNavbar()"
        >
          <font-awesome-icon icon="bars" />
        </button>
      </div>
      <div
        v-bind:class="{ hidden: !showMenu, flex: showMenu }"
        class="lg:flex lg:flex-grow items-center"
      >
        <ul role="navigation">
          <li class="nav-item">
            <span class="ml-2 px-3 py-2 flex items-center text-xs leading-snug">
              <router-link
                to="/"
                class="
                  text-gray-400
                  capitalize
                  font-oxygen
                  hover:bg-gray-100
                  hover:text-gray-600
                  text-lg
                  p-2
                  rounded
                "
                >inicio
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="ml-2 px-3 py-2 flex items-center text-xs leading-snug">
              <router-link
                to="/cv"
                class="
                  text-gray-400
                  uppercase
                  font-oxygen
                  hover:bg-gray-100
                  hover:text-gray-600
                  text-lg
                  p-2
                "
                >cv
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="ml-2 px-3 py-2 flex items-center text-xs leading-snug">
              <router-link
                to="/produccion-artistica/casi-un-destino"
                class="
                  text-gray-400
                  capitalize
                  font-oxygen
                  hover:bg-gray-100
                  hover:text-gray-600
                  text-lg
                  p-2
                  rounded
                "
                >producción artística
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="ml-2 px-3 py-2 flex items-center text-xs leading-snug">
              <router-link
                to="/criticas"
                class="
                  text-gray-400
                  capitalize
                  font-oxygen
                  hover:bg-gray-100
                  hover:text-gray-600
                  text-lg
                  p-2
                  rounded
                "
                >criticas
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="ml-2 px-3 py-2 flex items-center text-xs leading-snug">
              <router-link
                to="/contacto"
                class="
                  text-gray-400
                  capitalize
                  font-oxygen
                  hover:bg-gray-100
                  hover:text-gray-600
                  text-lg
                  p-2
                  rounded
                "
                >contacto
              </router-link>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    toggleNavbar: function() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>

<style scoped>
a.router-link-exact-active {
  background: #e5e7eb;
  color: #4f596b;
}

a.no-activo {
  background: none !important;
}
</style>
