<template>
  <footer>
    <p class="m-auto">
      website beatriz rodriguez gauna
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
